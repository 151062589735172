import Axios from 'axios';
import { BASE_URL } from './config';
let APIKit = Axios.create({
  baseURL: BASE_URL,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json',
    // 'Accept': 'application/json'
  },
});

APIKit.interceptors.request.use(
  async config => {
    // Do something before request is sent
    //If the header does not contain the token and the url not public, redirect to login

    // var customHeaders = await Utils.getHeaders();
    var customHeaders = {};
    const token = localStorage.getItem('accessToken');
    // const token = await Utils.getAsyncStorage().getToken();
    if (token) customHeaders = {...customHeaders, 'x-api-key': `${token}`,"Authorization" : `Bearer ${token}`};
    // else customHeaders = {...customHeaders, key: Utils.getConstants().API_KEY};

    // var customData = {};

    config.headers = {...config.headers, ...customHeaders};

    // const token = await Utils.getAsyncStorage().getToken();
    // if (token) customData = {...customData, 'access-token' : token};
    // else customData = {...customData, key: Utils.getConstants().API_KEY};

    // config.data = {...config.data, ...customData};

    // console.log('%%%%%%%%-params-%%%%%%%%%', config.data);

    return config;
  },
  function (error) {
    // Do something with request error
    // console.log('error request');
    // console.log(error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
APIKit.interceptors.response.use(
  async response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response.data;
  },
  async error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log('response', error);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      // console.log(error.response);
      // console.log('error code.......:  ', error.response.status);

      if (error.response.status === 403) {
        console.log('login works......................');
        // const isLoggedIn = await Utils.getAsyncStorage().isLoggedIn();

        // Utils.getAsyncStorage()
        //   .clearSession()
        //   .then(() => {
        //     if (!isLoggedIn) {
        //       Utils.showToast('Your session has been expired!');
        //       // RootNavigation.replace('Login');
        //     }
        //   });
        
      } else if (error.response.data.error) {
        // Utils.showToast(error.response.data.error.description);
        // console.log(error.response.data.error);
        return error.response.data.error;
      } else {
        // Utils.showToast('Internal error occured');
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      console.log('error.request');
      // Utils.showToast('Network error occured');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      // Utils.showToast(error.message);
      return error;
    }
    return Promise.reject(error);
  },
);
export default APIKit;