import { lazy } from "react";
import Loadable from "../ui-component/Loadable";
const AuthGuard = Loadable(
  lazy(() => import("../views/pages/authentication/AuthGuard"))
);
const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
const MainOrders = Loadable(lazy(() => import("../views/order/MainOrders")));
const OrderDetails = Loadable(
  lazy(() => import("../views/order/OrderDetails"))
);

//store
const AddStore = Loadable(lazy(() => import("../views/store/AddStore")));
const ListStore = Loadable(lazy(() => import("../views/store/ListStore")));
const StoreSettings = Loadable(
  lazy(() => import("../views/store/StoreSettings"))
);
const StoreProfile = Loadable(
  lazy(() => import("../views/store/StoreProfile"))
);
const Addon = Loadable(lazy(() => import("../views/store/addons/Addon")));
const MenuOptions = Loadable(
  lazy(() => import("../views/store/options/MenuOptions"))
);
const Category = Loadable(
  lazy(() => import("../views/store/category/Category"))
);
const Menu = Loadable(lazy(() => import("../views/store/menu/Menu")));
const MenuList = Loadable(lazy(() => import("../views/store/menu/MenuList")));
const MenuUpload = Loadable(
  lazy(() => import("../views/store/adminAction/MenuUpload"))
);

//order
const ReviewOrder = Loadable(
  lazy(() => import("../views/order/review/ReviewOrder"))
);
const DeliveryBoyAdd = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyAdd"))
);
const DeliveryBoyList = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyList"))
);
const CustomerService = Loadable(
  lazy(() => import("../views/customerService/CustomerService"))
);
const ChangePassword = Loadable(
  lazy(() => import("../views/pages/authentication/ChangePassword"))
);

//offer
const DiscountOffer = Loadable(
  lazy(() => import("../views/offer/DiscountOffer"))
);
const CategoryOffer = Loadable(
  lazy(() => import("../views/offer/CategoryOffer"))
);
const ItemOffer = Loadable(lazy(() => import("../views/offer/ItemOffer")));

//performance Dialy
const DialyPerformance = Loadable(
  lazy(() => import("../views/report/performance/DialyPerformance"))
);
const WeeklyPerformance = Loadable(
  lazy(() => import("../views/report/performance/WeeklyPerformance"))
);
const MonthlyPerformance = Loadable(
  lazy(() => import("../views/report/performance/MonthlyPerformance"))
);

//Summary
const DialySummary = Loadable(
  lazy(() => import("../views/report/summary/DialySummary"))
);
const WeeklySummary = Loadable(
  lazy(() => import("../views/report/summary/WeeklySummary"))
);
const MonthlySummary = Loadable(
  lazy(() => import("../views/report/summary/MonthlySummary"))
);

//notification
const Notification = Loadable(
  lazy(() => import("../views/userNotification/userNotification"))
);
const UserUpdate = Loadable(
  lazy(() => import("../views/pages/authentication/userupdate"))
);
const OnlineOrder = Loadable(
  lazy(() => import("../views/report/onlineOrder/OnlineOrder"))
);
// const Notification = Loadable(
//   lazy(() => import("../views/pages/authentication/userupdate"))
// );
const MonthlyInvoice = Loadable(
  lazy(() => import("../views/report/MonthlyInvoice"))
);
//menu
const ViewMenu = Loadable(lazy(() => import("../views/store/menu/ViewMenu")));

const ViewTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/view"))
);
const StoreKeeper =  Loadable(
  lazy(() => import("../views/accounts/storeOfficer/OfficeStaff"))
);
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/store",
      children: [
        {
          path: "addons",
          element: <Addon />,
        },
        {
          path: "category",
          element: <Category />,
        },
        {
          path: "menu",
          element: <Menu />,
        },
        {
          path: "menu/:id",
          element: <MenuList />,
        },
        {
          path: "settings",
          element: <StoreSettings />,
        },
        {
          path: "storeProfile/viewMenu/:catID",
          element: <ViewMenu />,
        },
      ],
    },
    {
      path: "/deliveryBoy",
      children: [
        {
          path: "add",
          element: <DeliveryBoyAdd />,
        },
        {
          path: "list",
          element: <DeliveryBoyList />,
        },
      ],
    },
    {
      path: "/customerService",
      element: <CustomerService />,
    },
    {
      path: "/branch",
      children: [
        {
          path: "order",
          element: <MainOrders />,
        },
      ],
    },
    {
      path: "/order",
      children: [
        {
          path: "view",
          element: <MainOrders />,
        },
        {
          path: "orderDetails/:id",
          element: <OrderDetails />,
        },
      ],
    },
    {
      path: "review",
      element: <ReviewOrder />,
    },
    // {
    //   path: "/offer",
    //   children: [
    //     {
    //       path: "combo_offer",
    //       element: <ComboOffer />,
    //     },
    //     {
    //       path: "discount",
    //       element: <DiscountOffer />,
    //     },
    //     {
    //       path: "category",
    //       element: <CategoryOffer />,
    //     },
    //     {
    //       path: "items",
    //       element: <ItemOffer />,
    //     },
    //   ],
    // },
    {
      path: "/report",
      children: [
        {
          path: "performance_report",
          children: [
            {
              path: "dialy",
              element: <DialyPerformance />,
            },
            {
              path: "weekly",
              element: <WeeklyPerformance />,
            },
            {
              path: "monthly",
              element: <MonthlyPerformance />,
            },
          ],
        },
        {
          path: "summary_report",
          children: [
            {
              path: "dialy",
              element: <DialySummary />,
            },
            {
              path: "weekly",
              element: <WeeklySummary />,
            },
            {
              path: "monthly",
              element: <MonthlySummary />,
            },
          ],
        },
        {
          path: "invoice",
          element: <MonthlyInvoice />,
        },
        {
          path: "onlineOrder",
          element: <OnlineOrder />,
        },
      ],
    },
    {
      path: "/userUpdate",
      element: <UserUpdate />,
    },
    {
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      path: "/notification/view",
      element: <Notification />,
    },
    // {
    //   path: "/feedback",
    //   element: <Complaints />,
    // },
    {
      path: "/taxinvoice",
      children: [
        {
          path: "view",
          element: <ViewTaxInvoice />,
        },
        
      ],
    },
    {
      path: "/storekeeper",
      children: [
        {
          path: "list",
          element: <StoreKeeper />,
        },
        
      ],
    },
  ],
};

export default MainRoutes;
