import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
export const orderSlice = createSlice({
    name: 'Order',
    initialState: {
        OrderList: [],
        filter: {
            from: new Date,
            to: new Date(),
            status: "All",
            order_type: "all",
        }
    },
    reducers: {
        addOrderList: (state, action) => {
            state.OrderList = action.payload.data;
        },
        setFilter: (state, action) => {
            state.filter[action.payload.name] = action.payload.data;
        },
        cleatReduxFilter: (state, action) => {
            state.filter = {
                from: new Date(),
                to: new Date(),
                status: "All",
                order_type: "all",
            }

        }
    }
})
export const { addOrderList, setFilter ,cleatReduxFilter} = orderSlice.actions;
export default orderSlice.reducer;