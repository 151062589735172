import {createSlice} from '@reduxjs/toolkit';
export const menuSlice = createSlice({
    name :'Menu',
    initialState : {
        categories : []
    },
    reducers : {
        addCategoryData:(state,action)=>{
            state.categories = action.payload.data;
        }
    }
})
export const {addCategoryData} = menuSlice.actions;
export default menuSlice.reducer;