import { createSlice } from '@reduxjs/toolkit';
export const dashboaedSlice = createSlice({
    name: 'Order',
    initialState: {
        tails: {},
        // filter: {
        //     from: new Date,
        //     to: new Date(),
        //     status: 0,
        //     order_type: "all",
        // }
    },
    reducers: {
        addTails: (state, action) => {
            state.tails = action.payload.data;
        },
        // setFilter: (state, action) => {
        //     state.filter[action.payload.name] = action.payload.data;
        // }
    }
})
export const { addTails} = dashboaedSlice.actions;
export default dashboaedSlice.reducer;